






































































































































import { Component, Vue } from 'vue-property-decorator';
import ImageHeader from '@/components/partials/ImageHeader.vue';

@Component({
  components: { ImageHeader },
  metaInfo: {
    title: 'Über uns │ innovaMo',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'innovaMo – der Mobilitätsmarktplatz, der bestehende und neue intelligente Mobilitätslösungen für den ländlichen Raum bereitstellt und miteinander verbindet.',
      },
      {
        vmid: 'preview-image',
        property: 'og:image',
        content: 'https://www.innovamo.de/img/seo/abstract_subway.png',
      },
      {
        vmid: 'preview-image-twitter',
        property: 'twitter:image',
        content: 'https://www.innovamo.de/img/seo/abstract_subway.png',
      },
    ],
  },
})
export default class About extends Vue {
}
