



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ImageHeader extends Vue {
  @Prop({ required: true })
  private readonly text!: string;

  @Prop({ default: 'shutterstock_447406066.png' })
  private readonly imageName!: string;
}
